@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* primary */
    --color-primary-50: #fffbeb;
    --color-primary-100: #fef2c7;
    --color-primary-200: #fde48a;
    --color-primary-300: #fdd04c;
    --color-primary-400: #fcbb23;
    --color-primary: #f69c0f;
    --color-primary-600: #da7305;
    --color-primary-700: #b55008;
    --color-primary-800: #923e0e;
    --color-primary-900: #78330f;
    --color-primary-950: #451903;
    /* secondary */
    --color-secondary-50: #fefbe8;
    --color-secondary-100: #fef7c3;
    --color-secondary-200: #feee95;
    --color-secondary-300: #fde272;
    --color-secondary-400: #fac515;
    --color-secondary: #eaaa08;
    --color-secondary-600: #ca8504;
    --color-secondary-700: #a15c07;
    --color-secondary-800: #854a0e;
    --color-secondary-900: #713b12;
    /* success */
    --color-success-50: #ecfdf3;
    --color-success-100: #d1fadf;
    --color-success-200: #a6f4c5;
    --color-success-300: #6ce9a6;
    --color-success-400: #32d583;
    --color-success: #12b76a;
    --color-success-600: #039855;
    --color-success-700: #027a48;
    --color-success-800: #05603a;
    --color-success-900: #054f31;
    /* warning */
    --color-warning-50: #fffaeb;
    --color-warning-100: #fef0c7;
    --color-warning-200: #fedf89;
    --color-warning-300: #fec84b;
    --color-warning-400: #fdb022;
    --color-warning: #f79009;
    --color-warning-600: #dc6803;
    --color-warning-700: #b54708;
    --color-warning-800: #93370d;
    --color-warning-900: #7a2e0e;
    /* error */
    --color-error-50: #fef3f2;
    --color-error-100: #fee4e2;
    --color-error-200: #fecdca;
    --color-error-300: #fda29b;
    --color-error-400: #f97066;
    --color-error: #f04438;
    --color-error-600: #d92d20;
    --color-error-700: #b42318;
    --color-error-800: #912018;
    --color-error-900: #7a271a;
    /* neutral */
    --color-neutral-25: #f6f6f6;
    --color-neutral-50: #e9e9e9;
    --color-neutral-100: #d8d8d8;
    --color-neutral-200: #e3e3e3;
    --color-neutral-300: #c6c6c6;
    --color-neutral-400: #b4b4b4;
    --color-neutral: #8d8d8d;
    --color-neutral-550: #676767;
    --color-neutral-600: #363636;
    --color-neutral-700: #343434;
    --color-neutral-800: #272727;
    --color-neutral-900: #191b1e;
    --color-neutral-950: #0d0d0d;
    /* ... */
  }
}

body {
  margin: 0;
  font-family: 'M PLUS 1', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100dvh;
  background-color: lightgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

::-webkit-scrollbar {
  width: 0;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: var(--color-neutral-25);
}

::-webkit-scrollbar-thumb {
  background: var(--color-neutral-200);
  border-radius: 8px;
}

/* ReactGoogleAutocomplete */
.pac-container {
  border-top: none;
}

/* Button play video */
video::-webkit-media-controls-overlay-play-button {
  -webkit-appearance: media-overlay-play-button;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  border: none;
  box-sizing: border-box;
  background-color: transparent;
  width: 80px;
  height: 80px;
  padding: 0;
  scale: 0.6666;
}
